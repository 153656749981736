import { Head } from "vike-react/Head";

export default function DefaultHead() {
  return (
    <>
      <Head>
        <meta
          name="description"
          content="Multitool.xyz offers a collection of free online utilities including file conversions, image filters, calculators, and more."
        />
        <meta
          name="keywords"
          content="Multitool, file conversion, online calculator, free utilities, APIs, file tools, unit converters, free online tools"
        />
      </Head>
    </>
  );
}
